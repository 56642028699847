import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
<footer className="g-footer" role="contentinfo">
<div className="g-footerNav">
<div className="g-footer_inner">
<div className="l-tile l-tile-4-lg l-tile-1-md l-tile-1-sm">
<div className="l-tile_item">
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<ul className="g-footerNav_list">
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
</ul>
</div>
<div className="l-tile_item">
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<ul className="g-footerNav_list">
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
</ul>
</div>
<div className="l-tile_item">
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<ul className="g-footerNav_list">
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerNav_listItem"><a className="g-footerNav_listItemLink" href="#">リンク</a></li>
</ul>
</div>
<div className="l-tile_item">
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
<p className="g-footerNav_parent"><a className="g-footerNav_parentLink" href="#">カテゴリラベル</a></p>
</div>
</div>
</div>
</div>
<div className="g-footer_separator">
<div className="g-footer_inner">
<div className="g-footerSubNav">
<ul className="g-footerSubNav_list">
<li className="g-footerSubNav_listItem"><a className="g-footerSubNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerSubNav_listItem"><a className="g-footerSubNav_listItemLink" href="#">リンク</a></li>
<li className="g-footerSubNav_listItem"><a className="g-footerSubNav_listItemLink" href="#">リンク</a></li>
</ul>
</div>
<p className="g-footer_copyright"><small className="g-footer_copyrightText">© Company Name., All Rights Reserved.</small></p>
</div>
</div>
</footer>
);

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
