import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
<header role="banner" className="g-header" data-js-hamburger data-js-header>
<div className="g-header_fog" data-js-hamburger-overlay>
</div>
<div className="g-header_inner">
<div className="g-header_root">
<div className="g-headerTitle">
<div className="g-headerLogo">
<a className="g-headerLogo_link" href="/">
<div className="g-headerLogo_tagline">サイトショルダー</div>
<div className="g-headerLogo_img">
<img src="/assets/img/logo.svg" alt="サイトタイトル" width="143" height="27" />
</div>
</a>
</div>
<div className="g-headerSwitch" data-js-hamburger-switch>
<p className="g-headerSwitch_icon">
<a className="g-headerSwitch_iconLink g-headerSwitch_iconLink-search" href="#" data-js-hamburger-trigger="lang" aria-haspopup="false" aria-expanded="false">
<img className="i-button is-close" src="/assets/img/icons/ic_globe.svg" alt="言語切替メニューを開く" />
<img className="i-button is-open" src="/assets/img/icons/ic_close.svg" alt="言語切替メニューを閉じる" />
</a></p>
<p className="g-headerSwitch_icon">
<a className="g-headerSwitch_iconLink g-headerSwitch_iconLink-hamburger" href="#" data-js-hamburger-trigger="menu" aria-haspopup="false" aria-expanded="false">
<img className="i-button is-close" src="/assets/img/icons/ic_menu.svg" alt="メニューを開く" />
<img className="i-button is-open" src="/assets/img/icons/ic_close.svg" alt="メニューを閉じる" />
</a></p>
</div>
</div>
<div className="g-headerMenu" data-js-dropdown>
<div className="g-headerMenu_inner" data-js-hamburger-content="" aria-hidden="false">
<div className="g-headerMenu_root">
<nav className="g-headerNav" data-js-hamburger-category="menu">
<ul className="g-headerNav_list">
<li className="g-headerNav_listItem">
<a className="g-headerNav_listItemLink" href="/">TOP</a>
</li>
<li className="g-headerNav_listItem">
<a className="g-headerNav_listItemLink g-headerNav_listItemLink-current" href="#">カテゴリ</a>
</li>
<li className="g-headerNav_listItem g-headerNavDropdown" data-js-dropdown-wrapper>
<a className="g-headerNav_listItemLink g-headerNavDropdown_trigger" href="#" data-js-dropdown-trigger aria-expanded="false">
ドロップダウンメニュー
<img className="i-label i-label-right open" src="/assets/img/icons/ic_tri_up.svg" alt="メニューを閉じる" />
<img className="i-label i-label-right close" src="/assets/img/icons/ic_tri_down.svg" alt="メニューを開く" /></a>
<div className="g-headerNavDropdown_content" data-js-dropdown-content aria-hidden="true">
<div className="g-headerNavDropdown_contentInner">
<p className="g-headerNavDropdown_link"><a href="#">カテゴリトップ</a></p>
<div className="g-headerNavDropdown_nav">
<div className="l-tile l-tile-4">
<div className="l-tile_item">
<div className="c-card">
<a href="#">
<div className="c-card_img c-img">
<img src="//picsum.photos/g/600/200/?gravity=north" alt="" />
</div>
<p className="c-card_title c-text">サブカテゴリ</p>
</a>
</div>
</div>
<div className="l-tile_item">
<div className="c-card">
<a href="#">
<div className="c-card_img c-img">
<img src="//picsum.photos/g/600/200/?gravity=east" alt="" />
</div>
<p className="c-card_title c-text">サブカテゴリ</p>
</a>
</div>
</div>
<div className="l-tile_item">
<div className="c-card">
<a href="#">
<div className="c-card_img c-img">
<img src="//picsum.photos/g/600/200/?gravity=south" alt="" />
</div>
<p className="c-card_title c-text">サブカテゴリ</p>
</a>
</div>
</div>
<div className="l-tile_item">
<div className="c-card">
<a href="#">
<div className="c-card_img c-img">
<img src="//picsum.photos/g/600/200/?gravity=west" alt="" />
</div>
<p className="c-card_title c-text">サブカテゴリ</p>
</a>
</div>
</div>
</div>
</div>
</div>
</div>
</li>
<li className="g-headerNav_listItem g-headerNavDropdown" data-js-dropdown-wrapper>
<a className="g-headerNav_listItemLink g-headerNavDropdown_trigger" href="#" data-js-dropdown-trigger aria-expanded="false">
ドロップダウンメニュー
<img className="i-label i-label-right open" src="/assets/img/icons/ic_tri_up.svg" alt="メニューを閉じる" />
<img className="i-label i-label-right close" src="/assets/img/icons/ic_tri_down.svg" alt="メニューを開く" /></a>
<div className="g-headerNavDropdown_content" data-js-dropdown-content aria-hidden="true">
<div className="g-headerNavDropdown_contentInner">
<p className="g-headerNavDropdown_link"><a href="#">カテゴリトップ</a></p>
<div className="g-headerNavDropdown_nav">
<ul className="l-tile l-tile-4 l-gapNone">
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
<li className="l-tile_item"><a href="#">サブカテゴリ</a></li>
</ul>
</div>
</div>
</div>
</li>
</ul>
</nav>
<div className="g-headerSubNav" data-js-hamburger-category="menu">
<ul className="g-headerSubNav_list">
<li className="g-headerSubNav_listItem"><a className="g-headerSubNav_listItemLink" href="#">サブメニュー</a></li>
<li className="g-headerSubNav_listItem"><a className="g-headerSubNav_listItemLink" href="#">サブメニュー</a></li>
<li className="g-headerSubNav_listItem"><a className="g-headerSubNav_listItemLink" href="#">サブメニュー</a></li>
</ul>
</div>
<div className="g-headerSearch g-headerSearch-dropdown" data-js-hamburger-category="menu">
<div className="g-headerSearch_inner" data-js-dropdown-wrapper>
<a className="g-headerSearch_trigger" href="#" data-js-search-trigger data-js-dropdown-trigger aria-expanded="false"><img className="i-button i-button-small" src="/assets/img/icons/ic_search.svg" alt="検索ボタン" /></a>
<div className="g-headerSearch_body" data-js-dropdown-content aria-hidden="true">
<div className="g-headerSearch_bodyInner">
<div className="l-grid l-grid-center">
<div className="l-grid_item l-grid_item-8-lg l-grid_item-12-md l-grid_item-12-sm">
<div className="c-searchBox">
<form className="c-searchBox_form" action="">
<input className="c-searchBox_input" type="text" placeholder="Placeholder" data-js-search-input />
<button type="submit" className="c-searchBox_submit">
<img className="i-button i-button-small" src="/assets/img/icons/ic_search.svg" alt="検索ボタン" />
</button>
</form>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="g-headerLang" data-js-hamburger-category="lang">
<div className="g-headerLang_selector" data-js-dropdown-wrapper>
<a href="#" className=" g-headerLang_selected" data-js-dropdown-trigger aria-expanded="false">
<img className="i-label i-label-left" src="/assets/img/icons/ic_globe.svg" alt="言語切替" />
Japanese
<img className="i-label i-label-right open" src="/assets/img/icons/ic_tri_down.svg" alt="言語切替メニューを開く" />
<img className="i-label i-label-right close" src="/assets/img/icons/ic_tri_up.svg" alt="言語切替メニューを閉じる" />
</a>
<div className="g-headerLang_content" data-js-dropdown-content aria-hidden="true">
<ul className="g-headerLang_list">
<li className="g-headerLang_listItem"><a className="g-headerLang_listItemLink" href="#">English</a></li>
<li className="g-headerLang_listItem"><a className="g-headerLang_listItemLink" href="#">Spanish</a></li>
<li className="g-headerLang_listItem"><a className="g-headerLang_listItemLink is-active" href="#">Japanese</a></li>
<li className="g-headerLang_listItem"><a className="g-headerLang_listItemLink" href="#">Chinese</a></li>
<li className="g-headerLang_listItem"><a className="g-headerLang_listItemLink" href="#">Korean</a></li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
