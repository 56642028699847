import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Lib = ({ siteTitle }) => (
    /*
    <script src="/assets/js/main.js"></script>
    */
    ''
);

Lib.propTypes = {
}

Lib.defaultProps = {
}

export default Lib
